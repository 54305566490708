<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import axios from "axios";
// import Swal from "sweetalert2";
import {
  linewithDataChart,
} from "./data-apex";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
      linewithDataChart: linewithDataChart,
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 1,
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fa-cc-visa", "Visa"],
          index: 2,
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3,
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 4,
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fa-cc-visa", "Visa"],
          index: 5,
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6,
        },
      ],
    };
  },
  mounted() {
    if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
    } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
    }
    // let self = this;
  },
  methods: {
    // cekAksesMenu() {
    //   let self = this;
    //   var config_axios = {
    //     method: "get",
    //     url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-access-menu",
    //     params: {
    //       role_id: self.sessionRoleId,
    //       link_name: self.namePathUrl,
    //     },
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: "Bearer " + localStorage.access_token,
    //     },
    //   };
    //   axios(config_axios)
    //     .then((response) => {
    //       let response_data = response.data;
    //       let response_data_fix = response_data.data.status_access;
    //       if (response_data.meta.code == 200) {
    //         if (response_data_fix == false) {
    //           let timerInterval;
    //           Swal.fire({
    //             icon: "warning",
    //             title: "Oppss",
    //             text: "Mohon maaf anda tidak diberikan akses pada halaman ini.",
    //             timer: 2000,
    //             timerProgressBar: true,
    //             showCancelButton: false,
    //             showConfirmButton: false,
    //             didOpen: () => {
    //               timerInterval = setInterval(() => {
    //                 const content = Swal.getContent();
    //                 if (content) {
    //                   const b = content.querySelector("b");
    //                   if (b) {
    //                     b.textContent = Swal.getTimerLeft();
    //                   }
    //                 }
    //               }, 100);
    //             },
    //             willClose: () => {
    //               clearInterval(timerInterval);
    //             },
    //           }).then((result) => {
    //             /* Read more about handling dismissals below */
    //             if (result.dismiss === Swal.DismissReason.timer) {
    //               self.$router.push({ name: "dashboard" });
    //             }
    //           });
    //         }
    //       } else {
    //         Swal.fire({
    //           icon: "error",
    //           title: "Oops...",
    //           text: response_data_fix.data.message,
    //         });
    //       }
    //     })
    //     .catch((errors) => {
    //       console.log(errors);
    //     });
    // },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-4">
        <div class="row">
          <div class="col-xl-12">
            <div class="card mini-stats-wid" style="height: 120px">
              <div class="card-body">
                <div class="d-flex">
                  <span class="flex-grow-1 text-center mx-auto">          
                    <img src="@/assets/images/logo_lsp_biofarma.png" style="width: 20vh;">
                  </span>
                  <div>
                    <p class="text-muted fw-medium mb-2">Selamat Datang di Web Admin</p>
                    <p class="text-muted fw-medium mb-2">Aplikasi Lembaga Sertifikasi Profesi</p>
                    <p class="mb-0"><b>PT. BIO FARMA (PERSERO)</b></p>
                  </div>
                </div>
              </div>
              <!-- end card-body -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="row">
          <div class="col-xl-6">
            <div class="card mini-stats-wid" style="height: 120px">
              <div class="card-body">
                <div class="d-flex pt-3">
                  <div class="flex-grow-1">
                    <h4 class="text-muted fw-medium mb-2">197/days</h4>
                    <h4 class="mb-0">Visitor</h4>
                  </div>

                  <div
                    class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary"
                  >
                    <span class="avatar-title">
                      <i :class="`bx bx-copy-alt font-size-24`"></i>
                    </span>
                  </div>
                </div>
              </div>
              <!-- end card-body -->
            </div>
          </div>
          <div class="col-xl-6">
            <div class="card mini-stats-wid" style="height: 120px">
              <div class="card-body">
                <div class="d-flex pt-3">
                  <div class="flex-grow-1">
                    <h4 class="text-muted fw-medium mb-2">67</h4>
                    <h4 class="mb-0">Post</h4>
                  </div>

                  <div
                    class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary"
                  >
                    <span class="avatar-title">
                      <i :class="`bx bx-copy-alt font-size-24`"></i>
                    </span>
                  </div>
                </div>
              </div>
              <!-- end card-body -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4 text-center">Grafik Jumlah Visitor Website</h4>
            <!-- Line with Data Labels chart -->
            <apexchart
              class="apex-charts"
              height="380"
              type="line"
              dir="ltr"
              :series="linewithDataChart.series"
              :options="linewithDataChart.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
